
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder, PropType,  } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import { onMounted, watch } from 'vue'

export default defineComponent({
  components: {
    Grid,
  },
  props: {
    id: Number,
    visible: Boolean,
  },
  setup(props) {
    const model = CloudFun.current?.model
    const data = ref() as any;
    const session = ref() as any;
    const attendDetails = ref([]) as any;
    const report = reactive({
      Name: '',
      Count: 0,
      AttendCount: 0,
      Date: '',
      DoneCount: 0,
      Qnr: [],
      List1: [], //單選
      List2: [], //複選
      List3: [], //問答
      List4: [], //滿意度
    }) as any;
    // const grid = ref<any>();
    // const gridOptions: GridOptions = {
    //   title: '問題',
    //   canCreate: false,
    //   canUpdate: false,
    //   canDelete: false,
    //   canRead: false,
    //   multiselect: false,
    //   showFooter: true,      
    //   columns: [
    //     { field: 'Question.Title', title: '問卷題目', showOverflow: true, sortable: true, resizable: false },
    //     { field: 'Question.Type', title: '類型', showHeaderOverflow: true, showOverflow: true, sortable: true,
    //       formatter: ({ cellValue }) => {
    //         const item = model?.enums && Object.values(model?.enums.QuestionType).find((e) => e.Value === cellValue);
    //         return item ? item.Name : "";
    //       }
    //     },
    //     { field: 'AnswerText', title: '回答內容', showOverflow: true, sortable: true, resizable: false },   
    //     { field: 'OtherText', title: '其他說明', showOverflow: true, sortable: true, resizable: false },
    //   ],
    //   promises: { query: props.promises?.query, queryAll: props.promises?.queryAll, save: props.promises?.save },
    //   modalConfig: { width: "60%", height: "600", showFooter: true },
    // }

    // const formOptions: VxeFormProps = {
    //   titleWidth: 100,
    //   titleAlign: 'right',
    //   items: [
    //     {
    //       field: 'Question.Title',
    //       title: '問卷題目',
    //       span: 12,
    //       itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字' }, }
    //     },
    //     {
    //       field: 'AnswerText',
    //       title: '回答內容',
    //       span: 12,
    //       itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字' }, }
    //     },
    //     {
    //       field: 'OtherText',
    //       title: '其他說明',
    //       span: 12,
    //       itemRender: { name: '$input', props: { type: 'text', placeholder: '請輸入文字' }, }
    //     },
    //   ],
    //   rules:{
    //     Title: [{ type: 'string', required: true }],
    //   }
    // }

    const loadData = async () => {
      session.value = await model?.dispatch("session/find", props.id);
      console.log(session.value);

      var condition = new Condition().and("SessionId", Operator.Equal, props.id!.toString()).and("AttendMember.Attend.DataMode", Operator.Equal, 0)
      attendDetails.value = await model?.dispatch("attendDetail/query", { condition });
      console.log(attendDetails.value)

      condition = new Condition().and("AttendDetail.SessionId", Operator.Equal, props.id!.toString());
      var sortings = [{ column: "Question.Type", order: 1 },{ column: "Question.Ordinal", order: 0 },{ column: "CreatedTime", order: 1 }];
      data.value = await model?.dispatch("attendMemberQuestion/query", { condition, sortings });
      console.log(data.value);

      if(session.value && attendDetails.value && data.value) {
        //report
        report.Qnr = await model?.dispatch("qnr/find", session.value.QnrId);
        console.log(report.Qnr);
        report.Name = session.value.Name ?? '';
        report.Count = attendDetails.value.length ?? 0;
        report.Date = session.value.Date ? formatDate(session.value.Date, 'yyyy/MM/dd') : '';
        report.AttendCount = attendDetails.value.filter((e: any) => e.Attended).length ?? 0;
        var done = data.value.map((e: any) => e.AttendDetailId).filter((e: any, index: any, arr: any) => {
          return arr.indexOf(e) === index;
        });
        report.DoneCount = done.length;
        console.log(report.DoneCount);

        report.List1 = data.value.filter((e: any) => e.Question.Type == 1) //單選
        report.List2 = data.value.filter((e: any) => e.Question.Type == 2) //複選
        report.List3 = data.value.filter((e: any) => e.Question.Type == 3) //問答
        report.List4 = data.value.filter((e: any) => e.Question.Type == 4) //滿意度
      }
    }

    onMounted(async() => {
      await loadData();
    })

    watch(() => props.visible, async (value: any) => {
      if(value && props.id) {
        await loadData();
      }
    })

    // watch(() => props.id, async (value: any) => {
    //   console.log(value)
      
    // }, { immediate: true });

    return {
      data,
      report,
      // grid,
      // gridOptions,
      // formOptions,
    }
  },
  methods: {
    refresh() {
      (this.$refs.grid as any).refresh();
    },
    async onGridEdit (row: any, callback: any) {
      callback()
    },
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
});
