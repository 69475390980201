
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder, PropType } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
export default defineComponent({
  components: {
    Grid,
  },
  props: {
    sid: Number,
    promises: Object as PropType<{
      /** 非同步查詢 */
      query?: (params: {
        page: number;
        pageSize: number;
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<{ data: any[]; totalCount: number }>;
      /** 查詢所有資料 */
      queryAll?: (params?: {
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<any[]>;
      /** 儲存已變更資料 */
      save?: (params: {
        insertRows?: any[];
        updateRows?: any[];
        deleteRows?: any[];
      }) => Promise<void>;
    }>
  },
  setup(props) {
    const model = CloudFun.current?.model

    const grid = ref<any>();
    const gridOptions: GridOptions = {
      title: '場次折扣',
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      multiselect: false,
      showFooter: true,      
      columns: [
        { field: 'Discount', title: '折扣', showOverflow: true, sortable: true, resizable: false },
        {
        field: 'DiscountType',
        title: '折扣類型',
        showOverflow: true, sortable: true, resizable: false,
        formatter: ({ cellValue }) => {
          const item = model?.enums && Object.values(model?.enums.DiscountType).find((e) => e.Value === cellValue);
          return item ? item.Name : "";
        }
        },
        { field: 'DueDate', title: '優惠截止日期', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => formatDate(new Date(cellValue), 'yyyy/MM/dd') },
      ],
      promises: { query: props.promises?.query, queryAll: props.promises?.queryAll, save: props.promises?.save },
      modalConfig: { width: "30%", height: "200", showFooter: true },
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'Discount',
          title: '折扣',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字' }, }
        },
        {
          field: 'DiscountType',
          title: '折扣類型',
          span: 12,
          itemRender: { name: "$select", options: model ? Object.values(model.enums.DiscountType).filter(e => e.Value != 0).map(e => { return { label: e.Name, value: e.Value } }) : [] }
        },
        {
          field: 'DueDate',
          title: '優惠截止日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
      ],
      rules:{
        Discount: [{ type: 'string', required: true }],
      }
    }

    const initData = (row: any, callback: any) => {
      console.log(row)
      row.Discount = 0;
      row.SessionId = props.sid;
      callback();
    }

    return {
      grid,
      initData,
      gridOptions,
      formOptions,
    }
  },
  methods: {
    refresh() {
      (this.$refs.grid as any).refresh();
    },
    async onGridEdit (row: any, callback: any) {
      // const entity = row.Id ? await this.$model.dispatch('documentRecord/find', row.Id) : undefined
      // Object.assign(row, entity)
      // const statuses = (Object.values(this.$model.enums.DocumentStatus) as any[]);
      // row.BeforeStatusName = statuses.find(e => e.Value === row.BeforeStatus).Name;
      // row.AfterStatusName = statuses.find(e => e.Value === row.AfterStatus).Name;
      //   if (entity) {
      //     entity.Xml.Time = new Date(entity.Xml.Time)
      //   }
      callback()
    },
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
});
