
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive, Sorting, SortOrder, PropType } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps, VxeTableProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import SpeakerTable from "@/components/activity/SpeakerTable.vue";
import AgendaFileUpload from "@/components/activity/AgendaFileUpload.vue";

export default defineComponent({
  components: {
    Grid,
    SpeakerTable,
    AgendaFileUpload,
  },
  props: {
    sid: Number,
    promises: Object as PropType<{
      /** 非同步查詢 */
      query?: (params: {
        page: number;
        pageSize: number;
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<{ data: any[]; totalCount: number }>;
      /** 查詢所有資料 */
      queryAll?: (params?: {
        keyword?: string;
        sortings?: Sorting[];
        condition?: Condition;
      }) => Promise<any[]>;
      /** 儲存已變更資料 */
      save?: (params: {
        insertRows?: any[];
        updateRows?: any[];
        deleteRows?: any[];
      }) => Promise<void>;
    }>
  },
  setup(props) {
    const model = CloudFun.current?.model
    const speakerTable = ref<any>();
    const grid = ref<any>();

    const displaySpeaker = (item: any) => { 
      const { cellValue } = item;
      return cellValue && cellValue.length ? cellValue.sort((a: any, b: any) => a.Ordinal - b.Ordinal).map((e: any) => e.Speaker?.Name) : "" 
    }

    const gridOptions: GridOptions = {
      title: '活動議程',
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      multiselect: false,
      showFooter: true,      
      columns: [
        { field: 'Name', title: '名稱', showOverflow: true, sortable: true, resizable: false },
        { field: 'StartTime', title: '議程開始時間', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => cellValue?.substring(0, 5) },
        { field: 'EndTime', title: '議程結束時間', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => cellValue?.substring(0, 5) },
        { field: 'Speakers', title: '演講人', showOverflow: true, sortable: true, resizable: false, formatter: displaySpeaker },
        { field: 'Ordinal', title: '排序', showHeaderOverflow: true, showOverflow: true, sortable: true, resizable: false, align: 'center' },
      ],
      promises: { query: props.promises?.query, queryAll: props.promises?.queryAll, save: props.promises?.save },
      modalConfig: { width: "50%", height: "500", showFooter: true },
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        {
          field: 'Name',
          title: '議程名稱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字' }, }
        },
        {
          field: 'Ordinal',
          title: '排序',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' }, }
        },
        {
          field: 'StartTime',
          title: '議程開始時間',
          span: 12,
          itemRender: { name: '$input', props: { type: 'time', valueFormat: "HH:mm" , labelFormat: 'HH:mm', placeholder: '請輸入時間', clearable: true } }
        },
        {
          field: 'EndTime',
          title: '議程結束時間',
          span: 12,
          itemRender: { name: '$input', props: { type: 'time', valueFormat: "HH:mm" , labelFormat: 'HH:mm', placeholder: '請輸入時間', clearable: true } }
        },
        {
          span: 24,
          slots: { default: 'column-speakers' }
        },
        {
          span: 24,
          slots: { default: 'file' }
        },
      ],
      rules:{
        Name: [{ type: 'string', required: true }],
        StartTime: [{ type: 'string', required: true }],
        EndTime: [{ type: 'string', required: true }],
      }
    }

    const initData = (row: any, callback: any) => {
      console.log(row)
      row.Ordinal = 0;
      row.SessionId = props.sid;
      callback();
    }

    const fileGrid = ref<any>()
    const fileModal = reactive({
      visible: false,
      selectedRow: {} as any,
      uploadOptions: {
        promises: {
          async query() {
            var condition = new Condition("AgendaId", Operator.Equal, fileModal.selectedRow.Id.toString());
            const sortings = [{ column: "CreatedTime", order: 1 }];
            return model?.dispatch('files/query', {
              condition: condition,
              sortings: sortings,
            });
          },
          insert(formData: FormData) {
            return model?.dispatch('files/insert', { formData, mode: 'other', entityId: 'Agenda_' + fileModal.selectedRow.Id, fileType: 0 });
          },
          delete(id: number) {
            return model?.dispatch('files/delete', id);
          }
        }
      },
      show(row: any) {
        //fileModal.visible = true;
        fileModal.selectedRow = row;
        if (fileGrid.value) fileGrid.value.refresh();
      }
    })


    return {
      grid,
      initData,
      gridOptions,
      formOptions,
      speakerTable,
      fileGrid,
      fileModal,
    }
  },
  methods: {
    refresh() {
      (this.$refs.grid as any).refresh();
    },
    async onGridEdit (row: any, callback: any) {
      const entity = row.Id ? await this.$model.dispatch('agenda/find', row.Id) : undefined
      this.fileModal.show(row)
      Object.assign(row, entity)
      callback()
    },
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
  }
});
