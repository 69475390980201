import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Grid = _resolveComponent("Grid")!

  return (_openBlock(), _createBlock(_component_Grid, _mergeProps({ ref: "grid" }, _ctx.gridOptions, {
    onAddNewRow: _ctx.initData,
    onRemoveSelectedRows: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hideBatchDropDown())),
    onEdit: _ctx.onGridEdit
  }), null, 16, ["onAddNewRow", "onEdit"]))
}