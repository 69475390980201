
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps } from "vxe-table";

interface Speaker {
  Name?: string;
  SpeakerId?: string;
  Speaker?: { Id: string, Name: string, Photo: any };
  Type: number;
  Ordinal: number;
  AgendaId: number;
}

export default defineComponent({
  name: "SurveyDetailTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    readonly: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          defaultSort: {  } //field: 'Ordinal', order: 'asc'
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: { } as any,
      selectedRow: null as any | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: any) {
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      async delete(rowId: any) {
        try {
          await model?.dispatch("surveyDetail/delete", rowId)
          emit('refresh');
        } catch (error) {
          console.error(error);
        }
      },
      async save() {
        if (props.aid) {
          try {
            table.formData.SurveyId = props.aid;
            await model?.dispatch("surveyDetail/insert", table.formData)         
            emit('refresh');
          } catch (error) {
            console.error(error);
          } 
        } else {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: "無法取得調查表Id",
          });
        }
      },
      rules: {
        Option: [{ required: true }],
      }
    })

    watch(() => props.data, async(value) => {
      table.formData = { } as any;
      table.data = value
    }, { immediate: true });

    const surveyDetailForm = ref<any>();

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Company",
          title: "公司名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("speaker/find", value), // eslint-disable-line
        query: params => model!.dispatch("speaker/query", params) // eslint-disable-line
      }
    };
    return {
      table,
      grid,
      gridOptions,
      surveyDetailForm,
      userSelectorOptions
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
