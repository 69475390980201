
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, onMounted, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import formatDate from 'xe-utils/toDateString';
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import SessionTable from "@/components/activity/SessionTable.vue";
import AttendTable from "@/components/activity/AttendTable.vue";
// import SurveyTable from "@/components/activity/SurveyTable.vue";
import UnitTable from "@/components/activity/UnitTable.vue";
import "@/global-components/ckeditor/styles.css";

export default defineComponent({
  components: {
    Grid,
    FileUploader,
    SelectBox,
    SessionTable,
    AttendTable,
    UnitTable,
    // SurveyTable,
  },
  setup () {
    const model = CloudFun.current?.model;
    const users = ref<{ label: string; value: string }[]>([]);
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]
    const uploader = ref<any>({});

    const gridOptions: GridOptions = {
      stripe: false,
      title: '活動管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      multiselect: false,
      printConfig: { sheetName: '活動管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '活動管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
      // { field: 'Number', title: '編號', showHeaderOverflow: true, showOverflow: true, sortable: true },
      {
        field: 'Name',
        title: '名稱',
        width: '25%',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        treeNode: false,
        editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        filters: [{ checked: false, label: '包含' }],
        slots: { filter: 'filter-name' }
      },
      { field: "Type", title: "類型", width: '8%', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => model ? Object.values(model.enums.ActivityType).find(e => e.Value === cellValue)?.Name : undefined },
      { field: 'Place', title: '地點', width: '15%', showHeaderOverflow: true, showOverflow: true, sortable: true },
      {
        field: 'StartTime',
        title: '活動日期',
        showHeaderOverflow: true,
        showOverflow: true,
        sortable: true,
        formatter: ({ cellValue }) => formatDate(new Date(cellValue), 'yyyy/MM/dd')
      },
      { field: 'Published', title: '已發布', showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => cellValue ? '是' : '否', align: 'center' },
      { field: 'IsFree', title: '是否免費', showHeaderOverflow: true, showOverflow: true, formatter: ({ cellValue }) => cellValue ? '是' : '否', align: 'center' },
      {
        title: '活動場次',
        width: '100',
        slots: { default: "session" }, align: 'center'
      },
      {
        title: '報名資料',
        width: '100',
        slots: { default: "attend" }, align: 'center'
      }
      // {
      //   title: '媒合調查表',
      //   width: '100',
      //   slots: { default: "survey" }, align: 'center'
      // }
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          // if (params.condition) params.condition.and('ParentId', Operator.IsNull)
          // else params.condition = new Condition('ParentId', Operator.IsNull)
          return model.dispatch('activity/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('activity/query') : undefined,
        save: model ? (params) => model.dispatch('activity/save', params) : undefined
      },
      modalConfig: { width: "70%", height: "700", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 130,
      titleAlign: 'right',
      items: [
        // { field: "Photo.Uri",
        //   title: "封面圖片",
        //   span: 12, slots: { default: "column-photo-and-remark" }
        // },
        // { field: "Banner.Uri",
        //   title: "橫幅圖片",
        //   span: 12, slots: { default: "column-banner-and-remark" }
        // },
        {
          field: 'Name',
          title: '活動名稱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Number',
          title: '編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '系統自動產生', clearable: true, disabled: 'true' }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'PlanName',
        //   title: '計畫名稱',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'OwnerId',
          title: '承辦人',
          span: 12,
          slots: { default: "column-report-user-id" }
        },
        { field: "Type", title: "活動類型", span: 12, itemRender: { name: "$select", options: model ? Object.values(model.enums.ActivityType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        // { field: "AreaType", title: "區域別", span: 12, itemRender: { name: "$select", options: model ? Object.values(model.enums.AreaType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },       
        {
          field: 'StartTime',
          title: '開始時間',
          span: 12,
          // titleWidth: 100,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        }, {
          field: 'EndTime',
          title: '結束時間',
          span: 12,
          // titleWidth: 100,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        {
          field: 'DueDate',
          title: '報名截止日期',
          span: 12,
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        },
        // {
        //   field: 'Contact',
        //   title: '報名窗口',
        //   span: 12,
        //   itemRender: { name: '$textarea', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'Place',
          title: '活動地點',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'Map',
        //   title: '交通地圖',
        //   span: 24,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        // {
        //   field: 'Additional.Content',
        //   title: '補充說明',
        //   span: 24,
        //   itemRender: { name: '$textarea', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'Published',
          title: '已發布',
          span: 12,
          // titleWidth: 100,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'IsFree',
          title: '是否免費',
          span: 12,
          // titleWidth: 100,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        // {
        //   field: 'HasForeign',
        //   title: '使用外籍欄位',
        //   span: 12,
        //   // titleWidth: 100,
        //   itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        // },
        {
          field: 'RequireQnr',
          title: '是否必填問卷',
          span: 12,
          // titleWidth: 100,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        {
          field: 'IsFull',
          title: '活動是否額滿',
          span: 12,
          // titleWidth: 100,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        // {
        //   field: 'EpaperId',
        //   title: '電子報資料',
        //   span: 12,
        //   slots: { default: "column-epaper" }
        // },
        // {
        //   field: 'QnrId',
        //   title: '問卷資料',
        //   span: 12,
        //   slots: { default: "column-qnr" }
        // },
        // {
        //   field: 'AttendNoticedTime',
        //   title: '報到通知已發送時間',
        //   span: 12,
        //   // titleWidth: 100,
        //   itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        // },
        // {
        //   field: 'DownloadNoticedTime',
        //   title: '簡報下載通知已發送時間',
        //   span: 12,
        //   // titleWidth: 100,
        //   itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true } }
        // },
        {
          field: 'ViewCount',
          title: '檢視次數',
          span: 12,
          // titleWidth: 100,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字', disabled: 'true' }, }
        },
        { span: 24, slots: { default: 'column-unit' } },
        { span: 24, slots: { default: 'html-editer' } },
        {
          span: 24,
          slots: { default: "files" }
        },
      ],
      rules: {
        Name: [{ type: 'string', required: true }],
        // PlanName: [{ type: 'string', required: true }],
        Type: [{ required: true }],
        IsFree: [{ required: true }],
        StartTime: [{ required: true }],
        EndTime: [{ required: true }],
        DueDate: [{ required: true }],
      }
    }

    const userSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Account",
          title: "帳號",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "姓名",
          width: "25%",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("user/find", value), // eslint-disable-line
        query: params => {
          params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'Name', order: 0 });
          return model!.dispatch("user/query", params) // eslint-disable-line
        }
      }
    };

    // function schemaCustomization(editor: any) {
    //   editor.model.schema.extend("$block", {
    //     allowAttributes: "classList"
    //   });
    //   editor.conversion.attributeToAttribute({
    //     model: {
    //       key: "classList"
    //     },
    //     view: {
    //       key: "class"
    //     }
    //   });
    // }

    const classicEditor = ClassicEditor;
    const contactConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "alignment",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
          "sourceEditing"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    };

    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "alignment",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo",
          "sourceEditing"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    };

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Title",
      valueField: "Id",
      addIfNotExists: false,
      columns: [
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: "Introduction", title: "說明", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      showHeader: true,
      promises: {
        find: (value) => model!.dispatch("qnr/find", value), // eslint-disable-line
        query: (params) => {
          params.condition = new Condition('Published', Operator.Equal, true)
          return model!.dispatch("qnr/query", params)
        }
      },
    };

    const epaperSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "",
      textField: "Subject",
      valueField: "Id",
      addIfNotExists: false,
      columns: [
        { field: "Subject", title: "電子報主旨", showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      showHeader: true,
      promises: {
        find: (value) => model!.dispatch("epaper/find", value), // eslint-disable-line
        query: (params) => {
          params.condition = new Condition('Published', Operator.Equal, true).and('Type', Operator.Equal, 2);
          return model!.dispatch("epaper/query", params)
        }
      },
    };

    const sessionGrid = ref<any>()
    const sessionModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            params.condition = new Condition()
              .and("ActivityId", Operator.Equal, sessionModal.selectedRow.Id.toString())
              .and(params.condition!);
            if (!params.sortings || params.sortings?.length === 0)
              params.sortings = [{ column: "Ordinal", order: 0 },{ column: "CreatedTime", order: 1 }];
            return model.dispatch("session/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('session/query') : undefined,
          save: model ? (params) => model.dispatch('session/save', params) : undefined
        }
      } as GridOptions,
      show(row: any) {
        sessionModal.visible = true;
        sessionModal.selectedRow = row;
        if (sessionGrid.value) sessionGrid.value.refresh();
      }
    })

    const attendGrid = ref<any>()
    const attendModal = reactive({
      visible: false,
      selectedRow: {} as any,
      gridOptions: {
        promises: {
          query: model ? (params) => {
            params.condition = new Condition()
              .and("ActivityId", Operator.Equal, attendModal.selectedRow.Id.toString())
              .and(params.condition!);
            if (!params.sortings || params.sortings?.length === 0)
              params.sortings = [{ column: "CreatedTime", order: 1 }];
            var selectedStatus = attendGrid?.value.getSelectedStatus();
            if(selectedStatus != null) {
              params.condition .and("Status", Operator.Equal, selectedStatus)
            }
            return model.dispatch("attend/query", params);
          } : undefined,
          queryAll: model ? () => model.dispatch('attend/query') : undefined,
          save: model ? (params) => model.dispatch('attend/save', params) : undefined
        }
      } as GridOptions,
      show(row: any) {
        attendModal.visible = true;
        attendModal.selectedRow = row;
        if (attendGrid.value) attendGrid.value.refresh();
      }
    })

    // const surveyGrid = ref<any>()
    // const surveyModal = reactive({
    //   visible: false,
    //   selectedRow: {} as any,
    //   // gridOptions: {
    //   //   promises: {
    //   //     query: model ? (params) => {
    //   //       params.condition = new Condition()
    //   //         .and("ActivityId", Operator.Equal, surveyModal.selectedRow.Id.toString())
    //   //         .and(params.condition!);
    //   //       if (!params.sortings || params.sortings?.length === 0)
    //   //         params.sortings = [{ column: "CreatedTime", order: 1 }];
    //   //       var selectedStatus = surveyGrid?.value.getSelectedStatus();
    //   //       if(selectedStatus != null) {
    //   //         params.condition .and("Status", Operator.Equal, selectedStatus)
    //   //       }
    //   //       return model.dispatch("attend/query", params);
    //   //     } : undefined,
    //   //     queryAll: model ? () => model.dispatch('attend/query') : undefined,
    //   //     save: model ? (params) => model.dispatch('attend/save', params) : undefined
    //   //   }
    //   // } as GridOptions,
    //   show(row: any) {
    //     // surveyModal.visible = true;
    //     // surveyModal.selectedRow = row;
    //     // if (surveyGrid.value) surveyGrid.value.refresh();
    //   },
    //   close() {
    //     surveyModal.visible = false;
    //   }
    // })

    const fileGrid = ref<any>()
    const fileModal = reactive({
      visible: false,
      selectedRow: {} as any,
      uploadOptions: {
        promises: {
          async query() {
            var condition = new Condition("ActivityId", Operator.Equal, fileModal.selectedRow.Id);
            const sortings = [{ column: "CreatedTime", order: 1 }];
            return model?.dispatch('files/query', {
              condition: condition,
              sortings: sortings,
            });
          },
          insert(formData: FormData) {
            return model?.dispatch('files/insert', { formData, mode: 'other', entityId: 'Activity_' + fileModal.selectedRow.Id, fileType: 0 });
          },
          delete(id: number) {
            return model?.dispatch('files/delete', id);
          }
        }
      },
      show(row: any) {
        //fileModal.visible = true;
        fileModal.selectedRow = row;
        if (fileGrid.value) fileGrid.value.refresh();
      }
    })

    const unitTable = ref<any>();

    return {
      grid,
      model,
      selectorOptions,
      userSelectorOptions,
      gridOptions,
      formOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
      uploader,
      contactConfig,
      editorConfig,
      classicEditor,
      sessionGrid,
      sessionModal,
      attendGrid,
      attendModal,
      fileGrid,
      fileModal,
      epaperSelectorOptions,
      unitTable,
      // surveyGrid,
      // surveyModal,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    },
    async uploaderFilter (current: VueUploadItem, original: VueUploadItem, prevent: any) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("activity/find", row.Id)
        : undefined;
      if (entity) {
        if (entity.Keywords && entity.Keywords.length) {
          entity.KeywordIds = entity.Keywords.map((e: any) => e.KeywordId);
        } else {
          entity.KeywordIds = [];
        }
        Object.assign(row, entity);
        this.fileModal.show(row)
      }
      callback();
    },
    refresh() {
      (this.$refs.grid as any).refresh();
    },
  }
})
