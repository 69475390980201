import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SurveyDetailTable = _resolveComponent("SurveyDetailTable")!
  const _component_vxe_form = _resolveComponent("vxe-form")!

  return (_openBlock(), _createBlock(_component_vxe_form, _mergeProps({
    class: "ml-3",
    ref: "form"
  }, _ctx.formOptions, {
    data: _ctx.data,
    onReset: _ctx.reset,
    onSubmit: _ctx.submitSurvey
  }), {
    "column-detail": _withCtx(({ data }) => [
      (data && data.Id)
        ? (_openBlock(), _createBlock(_component_SurveyDetailTable, {
            key: 0,
            ref: "surveyDetailTable",
            aid: data.Id,
            onRefresh: _ctx.refresh,
            data: data.Details
          }, null, 8, ["aid", "onRefresh", "data"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16, ["data", "onReset", "onSubmit"]))
}